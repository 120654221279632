import React from 'react';
import { Link } from 'gatsby';

const Back = () => (
  <a onClick={() => window.history.back() }
        title="Back">
    Back
  </a>
)

export default Back;